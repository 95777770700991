/*酒店接口*/
import {postAjaxRequest} from '@/utils/request'
import {yqfApiurl,yqfComApiurl} from '@/utils/webconfig'

//酒店查询信息
export function GetHotelSearch(parm, callback) {
	console.log('GetHotelSearchInfo');
	postAjaxRequest('/HotelApi/GetHotelSearch', parm, function(result) {
		callback(result);
	})	
}
//酒店列表
export function GetHotelListAsyncId(parm, callback) {
	console.log('GetHotelListAsyncId');
	postAjaxRequest('/HotelApi/GetHotelListAsyncId', parm, function(result) {
		callback(result);
	})	
}
//酒店列表
export function GetHotelListAsyncResult(parm, callback) {
	console.log('GetHotelListAsyncResult');
	postAjaxRequest('/HotelApi/GetHotelListAsyncResult', parm, function(result) {
		callback(result);
	})	
}
//刷新酒店列表条数
export function GetHotelListAsyncResultCount(parm, callback) {
	console.log('GetHotelListAsyncResultCount');
	postAjaxRequest('/HotelApi/GetHotelListAsyncResultCount', parm, function(result) {
		callback(result);
	})	
}
//酒店列表筛选信息
export function GetHotelListSummary(parm, callback) {
	console.log('GetHotelListSummary');
	postAjaxRequest('/HotelApi/GetHotelListSummary', parm, function(result) {
		callback(result);
	})	
}
//酒店详情
export function GetHotelDetailInfo(parm, callback) {
	console.log('GetHotelDetailInfo');
	postAjaxRequest('/HotelApi/GetHotelDetailInfo', parm, function(result) {
		callback(result);
	})	
}
//查询房价
export function GetHotelRoomAsyncId(parm, callback) {
	console.log('GetHotelRoomAsyncId');
	postAjaxRequest('/HotelApi/GetHotelRoomAsyncId', parm, function(result) {
		callback(result);
	})	
}
//查询房价
export function GetHotelRoomListByAsyncId(parm, callback) {
	console.log('GetHotelRoomListByAsyncId');
	postAjaxRequest('/HotelApi/GetHotelRoomListByAsyncId', parm, function(result) {
		callback(result);
	})	
}
//房价筛选信息
export function GetFilterHotelRoomPriceInfo(parm, callback) {
	console.log('GetFilterHotelRoomPriceInfo');
	postAjaxRequest('/HotelApi/GetFilterHotelRoomPriceInfo', parm, function(result) {
		callback(result);
	})	
}
//预订房间并验价
export function GetBookHotelRoom(parm, callback) {
	console.log('GetBookHotelRoom');
	postAjaxRequest('/HotelApi/GetBookHotelRoom', parm, function(result) {
		callback(result);
	})	
}
//酒店预订信息
export function GetHotelBookInfo(parm, callback) {
	console.log('GetHotelBookInfo');
	postAjaxRequest('/HotelApi/GetHotelBookInfo', parm, function(result) {
		callback(result);
	})	
}
//酒店旅客信息
export function GetHotelPassengerList(parm, callback) {
	console.log('GetHotelPassengerList');
	postAjaxRequest('/HotelApi/GetHotelPassengerList', parm, function(result) {
		callback(result);
	})	
}