<template>
	<div class="destina-a" v-if="ShowKeyWordBox" style="border:1px solid #eee">
		<!-- <el-row type="flex" justify="center" class="choose-destination">
			<div>关键字</div>
			<span class="iconfont icon-guanbi icon-close2 cursor" @click="bindClose"></span>
		</el-row> -->
		<!-- <el-row type="flex" justify="space-between" class="mt10">
			<el-input placeholder="拼音全称/英文全称/中文名/三字码" v-model="searchVal" @input="searchKeyWordInput" @focus="inputFocus" @blur="inputBlur"><i
				 slot="prefix" class="el-input__icon el-icon-search"></i></el-input>
			<el-button type="danger">搜索</el-button>
		</el-row> -->

		<el-scrollbar style="height:560px;text-align:left;line-height:1">
			<block v-if="searchVal!=''">
				<block v-if="searchCityResult!=''&&searchCityResult.length>0">
					<el-row class="pd10 search-b-type" v-for="(item, a) in searchCityResult" :key="a" type="flex">
						<span v-if="item.TypeID==20" class="iconfont icon-meitiku06 icon-jiudian"></span>
						<span v-else class="iconfont icon-meitiku06 icon-sousuo2"></span>
						<div @click="bindSelectBySearch(item)" style="width:288px;" class="cursor ml10">
							<div class="ft16">
								<span>{{item.CityNameCN }}</span>
							</div>
							<div class="ft12 gray9 mt10">
								<span>{{item.CountryName }}</span>
							</div>
						</div>
						<span class="place-difan" style="font-size:13px">{{item.TypeName }}</span>
					</el-row>
				</block>
				<block v-else>
					<el-row class="pd10 search-b-type" type="flex">
						<span class="iconfont icon-meitiku06 icon-sousuo2"></span>
						<div style="width:288px;" class="cursor ml10">
							<div class="ft16">
								<span v-if="!isInSerach">抱歉，找不到：{{searchVal}}</span>
								<span v-else>正在查询...</span>
							</div>
							<div class="ft12 gray9 mt10">
							</div>
						</div>
					</el-row>
				</block>
			</block>
			<block v-else>
				<div v-if="HotelSearchLogList!=null&&searchVal==''">
					<div class="hot-text2" v-if="HotelSearchLogList.HotKeywords!=null&&HotelSearchLogList.HotKeywords.length>0">
						<el-row type="flex" justify="space-between" align="middle" class="pd10 location">
							<div class="location-b">
								<img src="../../../assets/hot-text.png" alt="" style="width:18px;height:18px" class="i-block mr5" />
								<span class="ml5">热门关键字</span>
							</div>
							<div class="ft16 gray9 cursor" @click="isShowAllHotKeywords=!isShowAllHotKeywords">
								<span class="iconfont icon-ananzuiconv266 lan" v-if="isShowAllHotKeywords"></span>
								<span class="iconfont icon-jiantouxiamian lan" v-else></span>
							</div>
						</el-row>
						<el-row type="flex" align="middle" class="flex-box">
							<div v-for="(item, index) in HotelSearchLogList.HotKeywords" :key="index" style="width: 89px;">
								<el-row class="place-difang" type="flex" align="middle" justify="center" v-if="isShowAllHotKeywords||index<8">
									<span class="cursor place-b" :title="item.CName" @click="bindSelect(item,'HotKeywords')">{{item.CName}}</span>
								</el-row>
							</div>
						</el-row>
					</div>
					<div class="hot-text2" v-if="HotelSearchLogList.HotelNames!=null&&HotelSearchLogList.HotelNames.length>0">
						<el-row type="flex" justify="space-between" align="middle" class="pd10 location">
							<div class="location-b">
								<img src="../../../assets/hotHotel.png" alt="" style="width:18px;height:18px" class="i-block mr5" />
								<span class="ml5">热门酒店</span>
							</div>
							<div class="ft16 gray9 cursor" @click="isShowAllHotelNames=!isShowAllHotelNames">
								<span class="iconfont icon-ananzuiconv266 lan" v-if="isShowAllHotelNames"></span>
								<span class="iconfont icon-jiantouxiamian lan" v-else></span>
							</div>
						</el-row>
						<el-row type="flex" align="middle" class="flex-box">
							<div v-for="(item, index) in HotelSearchLogList.HotelNames" :key="index" style="width: 89px;">
								<el-row class="place-difang" type="flex" align="middle" justify="center" v-if="isShowAllHotelNames||index<8">
									<span class="cursor place-b" :title="item.Name" @click="bindSelect(item,'HotelNames')">{{item.Name}}</span>
								</el-row>
							</div>
						</el-row>
					</div>
					<div class="hot-text2" v-if="HotelSearchLogList.Businesses!=null&&HotelSearchLogList.Businesses.length>0">
						<el-row type="flex" justify="space-between" align="middle" class="pd10 location">
							<div class="location-b">
								<img src="../../../assets/shop.png" alt="" style="width:18px;height:18px" class="i-block mr5" />
								<span class="ml5">商业区</span>
							</div>
							<div class="ft16 gray9 cursor" @click="isShowAllBusinesses=!isShowAllBusinesses">
								<span class="iconfont icon-ananzuiconv266 lan" v-if="isShowAllBusinesses"></span>
								<span class="iconfont icon-jiantouxiamian lan" v-else></span>
							</div>
						</el-row>
						<el-row type="flex" align="middle" class="flex-box">
							<div v-for="(item, index) in HotelSearchLogList.Businesses" :key="index" style="width: 89px;">
								<el-row class="place-difang" type="flex" align="middle" justify="center" v-if="isShowAllBusinesses||index<8">
									<span class="cursor place-b" :title="item.Name" @click="bindSelect(item,'Businesses')">{{item.Name}}</span>
								</el-row>
							</div>
						</el-row>
					</div>
					<div class="hot-text2" v-if="HotelSearchLogList.Areas!=null&&HotelSearchLogList.Areas.length>0">
						<el-row type="flex" justify="space-between" align="middle" class="pd10 location">
							<div class="location-b">
								<img src="../../../assets/xingzheng.png" alt="" style="width:18px;height:18px" class="i-block mr5" />
								<span class="ml5">行政区域</span>
							</div>
							<div class="ft16 gray9 cursor" @click="isShowAllAreas=!isShowAllAreas">
								<span class="iconfont icon-ananzuiconv266 lan" v-if="isShowAllAreas"></span>
								<span class="iconfont icon-jiantouxiamian lan" v-else></span>
							</div>
						</el-row>
						<el-row type="flex" align="middle" class="flex-box">
							<div v-for="(item, index) in HotelSearchLogList.Areas" :key="index" style="width: 89px;">
								<el-row class="place-difang" type="flex" align="middle" justify="center" v-if="isShowAllAreas||index<8">
									<span class="cursor place-b" :title="item.Label" @click="bindSelect(item,'Areas')">{{item.Label}}</span>
								</el-row>
							</div>
						</el-row>
					</div>
					<div class="hot-text2" v-if="HotelSearchLogList.Brands!=null&&HotelSearchLogList.Brands.length>0">
						<el-row type="flex" justify="space-between" align="middle" class="pd10 location">
							<div class="location-b">
								<img src="../../../assets/pinpai.png" alt="" style="width:18px;height:18px" class="i-block mr5" />
								<span class="ml5">品牌</span>
							</div>
							<div class="ft16 gray9 cursor" @click="isShowAllBrands=!isShowAllBrands">
								<span class="iconfont icon-ananzuiconv266 lan" v-if="isShowAllBrands"></span>
								<span class="iconfont icon-jiantouxiamian lan" v-else></span>
							</div>
						</el-row>
						<el-row type="flex" align="middle" class="flex-box">
							<div v-for="(item, index) in HotelSearchLogList.Brands" :key="index" style="width: 89px;">
								<el-row class="place-difang" type="flex" align="middle" justify="center" v-if="isShowAllBrands||index<8">
									<span class="cursor place-b" :title="item.CName" @click="bindSelect(item,'Brands')">{{item.CName}}</span>
								</el-row>
							</div>
						</el-row>
					</div>
					<div class="hot-text2" v-if="HotelSearchLogList.Groups!=null&&HotelSearchLogList.Groups.length>0">
						<el-row type="flex" justify="space-between" align="middle" class="pd10 location">
							<div class="location-b">
								<img src="../../../assets/jituan.png" alt="" style="width:18px;height:18px" class="i-block mr5" />
								<span class="ml5">集团</span>
							</div>
							<div class="ft16 gray9 cursor" @click="isShowAllGroups=!isShowAllGroups">
								<span class="iconfont icon-ananzuiconv266 lan" v-if="isShowAllGroups"></span>
								<span class="iconfont icon-jiantouxiamian lan" v-else></span>
							</div>
						</el-row>
						<el-row type="flex" align="middle" class="flex-box">
							<div v-for="(item, index) in HotelSearchLogList.Groups" :key="index" style="width: 89px;">
								<el-row class="place-difang" type="flex" align="middle" justify="center" v-if="isShowAllGroups||index<8">
									<span class="cursor place-b" :title="item.CName" @click="bindSelect(item,'Groups')">{{item.CName}}</span>
								</el-row>
							</div>
						</el-row>
					</div>
				</div>
			</block>
		</el-scrollbar>
	</div>
</template>

<script>
	var util = require('@/utils/util.js');
	var destApi = require('@/api/destApi.js');
	var that;
	export default {
		name: 'keywords',
		props: ['ShowKeyWordBox', 'cityCodeKeyWord'],
		data() {
			return {
				searchVal: '',
				isInSerach: false,
				HotelSearchLogList: null,
				searchCityResult: '',
				isShowAllHotKeywords: false,
				isShowAllHotelNames: false,
				isShowAllBusinesses: false,
				isShowAllAreas: false,
				isShowAllBrands: false,
				isShowAllGroups: false,
				isShowAll: false,				
			};
		},
		mounted() {
			// this.handleScroll();

		},
		created() {
			that = this;
			var query = that.$route.query;
			// getHotelSearchLog();
		},
		methods: {			
			//搜索事件
			searchKeyWordInput: function(e) {
				that.isInSerach = true;
				var searchKey = e;
				that.searchVal = searchKey;
				console.log(e);
				// var searchKey = e.detail.value;
				destApi.GetSearchCityListByKey({
						searchKey: searchKey,
						hotcitytype: 'hotel',
						cityCode: that.cityCodeKeyWord,
						issearchlog: true
					},
					function(result) {
						console.log(result);
						var searchResult = '';
						that.isInSerach = false;
						if (result.code == 0) {
							searchResult = JSON.parse(result.data.searchResult); // var hotelCityResult = JSON.parse(result.data.hotelCityResult);

							console.log(searchResult);
						}
						that.searchCityResult = searchResult;
					}
				);
			},
			//输入框获得焦点
			inputFocus: function() {
				// that.isInSerach = true;
			},
			//输入框失去焦点
			inputBlur: function(e) {
				console.log(e);
				// if (that.searchVal == '') {
				// 	that.isInSerach = false;
				// }
			},
			bindClose(e) {
				this.$emit('bindHideKeyWordBox');
			},
			bindSelect(item, filterType) {
				console.log(item);
				console.log(filterType);
				let keyWord = "";
				let filterStr = filterType + "|";
				switch (filterType) {
					case 'Brands':
						filterStr += item.ID;
						break;
					case 'Businesses':
						filterStr += item.Code;
						break;
					case 'Groups':
						filterStr += item.ID;
						break;
					case 'Areas':
						filterStr += item.Code;
						break;
					case 'HotelNames':
						filterStr += item.Name;
						break;
					case 'HotKeywords':
						//类型 1-酒店集团 2-酒店品牌 3-地标 4-行政区域 5-酒店名字
						if(item.Type==1){
							filterStr='Groups|';
						}
						else if(item.Type==2){
							filterStr='Brands|';
						}
						else if(item.Type==3){
							filterStr='Businesses|';
						}
						else if(item.Type==4){
							filterStr='Areas|';
						}
						else{
							filterStr='Brands|';
						}
						filterStr += item.Code;
						break;
				}
				if (!util.isNullOrEmpty(item.Name)) {
					keyWord = item.Name;
				} else if (!util.isNullOrEmpty(item.CName)) {
					keyWord = item.CName;
				} else if (!util.isNullOrEmpty(item.Label)) {
					keyWord = item.Label;
				}
				console.log(keyWord);
				console.log(filterStr);
				// return;
				this.$emit('selectKeyWord', keyWord, filterStr);
			},
			bindSelectBySearch(item) {
				console.log(item);
				let filterStr = "";
				if(item.TypeID==10){
					//品牌
					filterStr="Brands|"+item.CityCode;
				}
				else if(item.TypeID==9){
					//品牌
					filterStr="Businesses|"+item.CityCode;
				}
				// else if(item.TypeID==20){
				// 	//品牌
				// 	filterStr="HotelNames|"+item.CityNameCN;
				// }
				// return;
				this.$emit('selectKeyWord', item.KeyWord,filterStr);
			},
			bindGetHotelSearchLog(cityCode) {

				that.cityCodeKeyWord = cityCode;
				getHotelSearchLog();
			},
			bindShowMore() {
				that.isShowAll = !that.isShowAll;
				// console.log(that.HotelSearchLogList.HotKeywords.length);

				// that.HotKeywords.length = that.HotelSearchLogList.HotKeywords.length;
			}
		}
	};
	//获取热门查询列表
	function getHotelSearchLog() {
		var parm = {
			cityCode: that.cityCodeKeyWord,
		};
		that.HotelSearchLogList = null;
		destApi.GetHotelSearchLog(parm, function(result) {
			console.log(result);
			if (result.code == 0) {
				that.HotelSearchLogList = result.data.HotelSearchLogList;
				// that.HotKeywords = that.HotelSearchLogList.HotKeywords;
				// that.HotKeywords.length = 8;
				// console.log(that.HotelSearchLogList.HotKeywords.length);
			}

		})
	}
</script>

<style></style>
